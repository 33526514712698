const skills = [
      {
      name: "html",
      title: "HTML",
      path: "../images/skills/cib-html5.svg",
      type: "dev"
      },
      {
      name: "css",
      title: "CSS",
      path: "../images/skills/cib-css3.svg",
      type: "dev"
      },
      {
      name: "bootstrap",
      title: "Boostrap",
      path: "../images/skills/cib-bootstrap.svg",
      type: "dev"
      },
      {
      name: "sass",
      title: "Sass",
      path: "../images/skills/cib-sass.svg",
      type: "dev"
      },
      {
      name: "accessibility",
      title: "Accessibility",
      path: "../images/skills/accessibility.svg",
      type: "dev"
      },
      {
      name: "javascript",
      title: "JavaScript",
      path: "../images/skills/cib-js.svg",
      type: "dev"
      },
      {
      name: "typescript",
      title: "TypeScript",
      path: "../images/skills/cib-typescript.svg",
      type: "dev"
      },
      {
      name: "react",
      title: "ReactJs",
      path: "../images/skills/cib-react.svg",
      type: "dev"
      },
      {
      name: "next",
      title: "NextJs",
      path: "../images/skills/nextjs.svg",
      type: "dev"
      },
      {
      name: "gatsby",
      title: "GatsbyJs",
      path: "../images/skills/gatsby.svg",
      type: "dev"
      },
      {
      name: "graphql",
      title: "GraphQl",
      path: "../images/skills/graphql.svg",
      type: "dev"
      },
      {
      name: "jestjs",
      title: "JestJs",
      path: "../images/skills/cib-jest.svg",
      type: "dev"
      },
      // {
      // name: "redux",
      // title: "Redux",
      // path: "../images/skills/cib-redux.svg",
      // type: "dev"
      // },
      {
      name: "mongo",
      title: "MongoDB",
      path: "../images/skills/cib-mongodb.svg",
      type: "dev"
      },
      {
      name: "node",
      title: "NodeJs",
      path: "../images/skills/nodejs.svg",
      type: "dev"
      },
      // {
      // name: "npm",
      // title: "NPM",
      // path: "../images/skills/cib-npm.svg",
      // type: "dev"
      // },
      // {
      // name: "postman",
      // title: "Postman",
      // path: "../images/skills/cib-postman.svg",
      // type: "dev"
      // },
      {
      name: "expo",
      title: "Expo",
      path: "../images/skills/cib-expo.svg",
      type: "dev"
      },
      // {
      // name: "vsc",
      // title: "VSC",
      // path: "../images/skills/cib-visual-studio-code.svg",
      // type: "dev"
      // },
      // {
      // name: "aws",
      // title: "AWS",
      // path: "../images/skills/cib-amazon-aws.svg",
      // type: "dev"
      // },
      // {
      // name: "netlify",
      // title: "Netlify",
      // path: "../images/skills/cib-netlify.svg",
      // type: "dev"
      // },
      {
      name: "git",
      title: "Git",
      path: "../images/skills/cib-git.svg",
      type: "git"
      },
      {
      name: "gitkraken",
      title: "Gitkraken",
      path: "../images/skills/cib-gitkraken.svg",
      type: "git"
      },
      {
      name: "github",
      title: "Github",
      path: "../images/skills/cib-github.svg",
      type: "git"
      },
      {
      name: "bitbucket",
      title: "Bitbucket",
      path: "../images/skills/cib-bitbucket.svg",
      type: "git"
      },
      {
      name: "gitlab",
      title: "Gitlab",
      path: "../images/skills/cib-gitlab.svg",
      type: "git"
      },
      {
      name: "wordpress",
      title: "Wordpress",
      path: "../images/skills/cib-wordpress 2.svg",
      type: "design"
      },
      {
      name: "figma",
      title: "Figma",
      path: "../images/skills/figma.svg",
      type: "design"
      },
      {
      name: "jira",
      title: "Jira",
      path: "../images/skills/cib-jira.svg",
      type: "manage"
      },
      {
      name: "confluence",
      title: "Confluence",
      path: "../images/skills/cib-confluence.svg",
      type: "manage"
      },
      // {
      // name: "team",
      // title: "Team Work",
      // path: "../images/skills/cil-fork.svg",
      // type: "soft"
      // },
      // {
      // name: "remote",
      // title: "Remote",
      // path: "../images/skills/cil-globe-alt.svg",
      // type: "soft"
      // },
      // {
      // name: "bilingual",
      // title: "Bilingual",
      // path: "../images/skills/cil-language.svg",
      // type: "soft"
      // },
      // {
      // name: "problem",
      // title: "Problem Solving",
      // path: "../images/skills/cil-puzzle.svg",
      // type: "soft"
      // },
      
];

export default skills;
